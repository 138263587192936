import React from 'react'
import get from 'lodash/get'

const Contact  = ({ slice }) => {

  return (
    <div className='relative max-w-7xl mx-auto lg:grid lg:grid-cols-5'>
      {get(slice.primary, 'text.html') && (
        <div className='prose prose-blue max-w-screen-2xl col-span-2 lg:px-5 mt-4' dangerouslySetInnerHTML={{__html:get(slice.primary, 'text.html')}} />
      )}
      <div className='col-span-3 lg:px-10 lg:border-l-2'>
        <form
          name='contact'
          method='POST'
          action='/success'
          data-netlify='true'
          data-netlify-honeypot='surname'
        >
          <input type='hidden' name='surname' />
          <input type='hidden' name='form-name' value='contact' />
          <div className='mb-4'>
            <label className='block text-lg font-light mt-4 mb-2' htmlFor='name'>What's your name?</label>
            <input className='shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:ring-red-500 ring-opacity-50' type='text' name='name' id='name' required />
          </div>
          <div className='mb-4'>
            <label className='block text-lg font-light mt-4 mb-2' htmlFor='email'>What's your email address?</label>
            <input className='shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:ring-red-500 ring-opacity-50' type='email' name='email' id='email' required />
          </div>
          <div className='mb-4'>
            <label className='block text-lg font-light mt-4 mb-2' htmlFor='phone'>What's your phone number?</label>
            <input className='shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:ring-red-500 ring-opacity-50 placeholder-gray-500' type='tel' name='phone' id='phone' placeholder='Optional' />
          </div>
          <div className='mb-4'>
            <label className='block text-lg font-light mt-4 mb-2' htmlFor='message'>How can we help?</label>
            <textarea rows='4' className='shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:ring-red-500 ring-opacity-50' name='message' id='message' required></textarea>
          </div>
          <div className='mb-4 text-white'>
            <button type='submit' className='button bg-coolGray-600 rounded w-full sm:w-auto'>Send your enquiry</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Contact
