import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { format, fromUnixTime } from 'date-fns'


export default function GoogleReviews({ slice }) {
  const data = useStaticQuery(graphql`
    query googlePlacesQuery {
      allGooglePlacesReview(
        filter: {rating: {eq: 5}, id: {ne: "GooglePlaces__Review__1613832580"}}
        sort: {fields: time, order: DESC}
      ) {
        nodes {
          author_name
          rating
          text
          time
          id
        }
      }
      allGooglePlacesPlace {
        nodes {
          place_id
          rating
        }
      }
    }
  `)

  const googlePlace = data.allGooglePlacesPlace.nodes.slice(0,1).pop();
  return (
    <div className='space-y-10'>
      <div className='space-y-4 text-center'>
        <div dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
        <div className='prose max-w-full' dangerouslySetInnerHTML={{__html:slice.primary.description.html}} />
      </div>
      <ul className='component-reviews space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-10'>
        {data.allGooglePlacesReview.nodes.map((review, index) => {
          let reviewDate = format(fromUnixTime(review.time), 'do MMMM yyyy')
          return (
            <li className='border-b pb-10 lg:border-transparent lg:flex lg:flex-col flex-1' key={`review-${index}`}>
              <div className='flex items-center justify-center h-12 w-12 rounded-md bg-emerald-500 text-white text-xl font-semibold'>
                {review.rating} <svg xmlns='http://www.w3.org/2000/svg' height='18px' width='18px' viewBox='0 0 24 24' className='inline-block' fill='currentColor'><path d='M0 0h24v24H0z' fill='none'/><path d='M0 0h24v24H0z' fill='none'/><path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'/></svg>
              </div>
              <blockquote className='prose my-4'>{review.text}</blockquote>
              <div className='mt-auto'>
                <span>{review.author_name}</span>
                <span className='text-gray-500 block text-base'>{reviewDate}</span>
              </div>
            </li>
          )
        })}
      </ul>
      {slice.primary.show_total && (
        <p className='prose max-w-full text-center px-8 xl:px-0'><span className='font-semibold'>{googlePlace.rating} out of 5</span> on <a target='_blank' rel='noopener noreferrer' href={`https://search.google.com/local/writereview?placeid=${googlePlace.place_id}`} className='underline'>Google Reviews</a></p>
      )}
    </div>
  )
}

GoogleReviews.propTypes = {
  slice: PropTypes.object.isRequired,
}
