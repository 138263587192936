import React from 'react'
import PropTypes from 'prop-types'
import Accordion from './accordion'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import Contact from './contact'
import Counter from './counter'
import GoogleReviews from './googleReviews'
import Hero from './hero'
import Image from './image'
import Map from './map'
import Partners from './partners'
import Quote from './quote'
import Text from './text'
import Video from './video'

const SliceList = ({ slice }) => {
  return (
    <>
      {slice.slice_type === 'accordion' && (
        <div className='max-w-screen-md w-full mx-auto px-4 pb-4 md:py-8 lg:py-16'>
          <Accordion slice={slice} />
        </div>
      )}
      {slice.slice_type === 'banner_with_caption' && (
        <div className='max-w-screen-xl w-full mx-auto md:py-8 sm:px-6 lg:px-8 lg:py-16'>
          <BannerWithCaption slice={slice} />
        </div>
      )}
      {slice.slice_type === 'card_deck' && (
        <div className='max-w-screen-xl w-full mx-auto px-4 sm:px-6 py-8 lg:py-16'>
          <CardDeck slice={slice} />
        </div>
      )}
      {slice.slice_type === 'contact' && (
        <div className='max-w-screen-xl w-full mx-auto px-4 pb-4 md:py-8'>
          <Contact slice={slice} />
        </div>
      )}
      {slice.slice_type === 'counter' && (
        <div className='max-w-screen-xl mx-auto px-4 pb-4 md:py-8'>
          <Counter slice={slice} />
        </div>
      )}
      {slice.slice_type === 'google_reviews' && (
        <div className='py-12 bg-white'>
          <div className='max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8'>
            <GoogleReviews slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'hero' && (
        <Hero slice={slice} />
      )}
      {slice.slice_type === 'image_gallery' && (
        <div className='max-w-screen-md w-full mx-auto px-3 pb-3 xl:px-8 bg-gray-100'>
          <Image autoplay delayLength={6000} slice={slice} />
        </div>
      )}
      {slice.slice_type === 'map' && (
        <div className='max-w-3xl mx-auto px-4 md:px-8'>
          <Map slice={slice} />
        </div>
      )}
      {slice.slice_type === 'partner_logos' && (
        <div className='bg-white mt-px'>
        <div className='container max-w-screen-xl mx-auto py-8 lg:py-12 px-8'>
          <Partners slice={slice} />
        </div>
        </div>
      )}
      {slice.slice_type === 'quote' && (
        <div className='bg-gradient-to-b from-warmGray-800 to-coolGray-600'>
          <div className='max-w-screen-md mx-auto px-8 sm:px-6 md:flex md:flex-col py-12 md:py-16'>
            <Quote slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'text' && (
        <div className='max-w-screen-md w-full mx-auto px-4 mb-4 py-8'>
          <Text slice={slice} />
        </div>
      )}
      {slice.slice_type === 'video' && (
        <div className='max-w-screen-md w-full mx-auto px-4 mb-4 md:py-8'>
          <Video slice={slice} />
        </div>
      )}
    </>
  )
}

SliceList.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default SliceList
