import React from "react";
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Img from 'gatsby-image'
import EmblaCarousel from './embla/emblaCarousel'

const Image = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type}`}>
      {get(slice.primary.gallery_name, 'text') && (
        <div className='prose prose-xl text-center max-w-none mb-10' dangerouslySetInnerHTML={{__html:get(slice.primary, 'gallery_name.html')}} />
      )}
      {slice.items.length > 1 ? (
        <EmblaCarousel autoplay={true} arrows={true} delayLength={4000} dots={true} loop={true}>
          {slice.items.map((item, index) => {
            return (
              <div className={`relative min-w-full ${'item' + (index === 0 ? ' active' : '')}`} key={`item-${index}`} aria-selected={(index === 0 ? 'true' : 'false')}>
                {item.image && (
                  <Img fluid={item.image.fluid} alt={item.image.alt}  />
                )}
                {item.text && (
                  <div className='caption relative z-10 py-3 px-6 w-full text-center'>
                    <div dangerouslySetInnerHTML={{__html:get(item, 'text.html')}} />
                  </div>
                )}
              </div>
            )
          })}
        </EmblaCarousel>
      ):(
        <div className='bg-white'>
          <Img fluid={slice.items.[0].image.fluid} alt={slice.items.[0].image.alt} />
          {slice.items.[0].image.text && (
            <div className='caption relative z-10 py-3 px-6 w-full text-center'>
              <div dangerouslySetInnerHTML={{__html:get(slice.items.[0].image, 'text.html')}} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

Image.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Image
