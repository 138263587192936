import React from 'react'
import PropTypes from 'prop-types'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'


const BannerWithCaption = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} relative overflow-hidden bg-white rounded border shadow-lg`}>
      <div className='pb-8 sm:pb-12 lg:max-w-2xl lg:w-1/2'>
        <div className='mt-10 mx-auto max-w-screen-xl px-4 md:px-8 sm:mt-16 lg:text-left text-center space-y-6'>
          {slice.primary.title.html && (
            <div dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
          )}
          {slice.primary.text.html && (
            <div className='prose max-w-full' dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
          )}
          <CheckLink className='button text-white bg-gradient-to-r from-red-500 to-red-800 mt-3' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink>
        </div>
      </div>
      <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
        <Img className='w-full h-full object-cover' fluid={slice.primary.image.fluid} alt={slice.primary.image.alt}/>
      </div>
    </div>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerWithCaption
