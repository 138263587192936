import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import CheckLink from '../components/checkLink'
import Seo from '../components/seo'
import SliceList from '../components/sliceList'
import { withPreview } from 'gatsby-source-prismic'

export const query = graphql`
  query Page($id: String) {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
    prismicPage(id: { eq: $id }) {
      ...PrismicPageFragment
    }
    prismicSocial {
      ...PrismicSocialFragment
    }
  }
`

const RenderBody = ({ data }) => {
  return (
    <div className='text-gray-800 bg-gray-100 text-lg'>
      <div className='relative overflow-hidden bg-gradient-to-b from-warmGray-800 to-coolGray-600 mb-5 md:mb-0'>
        <div className='max-w-screen-xl mx-auto page-header'>
          <div className={`relative pb-8 sm:pb-16 lg:pb-24 ${data.page_image.fluid && ('lg:text-left lg:w-2/3')} sm:text-center`}>
            <div className='mx-auto max-w-screen-xl px-4 mt-32 sm:px-6 md:mt-40 lg:px-8 xl:mt-48 space-y-6 text-white'>
              {data.page_title && <div className='hero' dangerouslySetInnerHTML={{__html:data.page_title.html}} />}
              {data.page_text && <div className='text-xl' dangerouslySetInnerHTML={{__html:data.page_text.html}} />}
              <div className='space-x-4'>
              {(data.page_button_link && data.page_button_text) && <CheckLink className='bg-gradient-to-r from-red-500 to-red-400 button hover:from-red-600' link={data.page_button_link}>{data.page_button_text}</CheckLink>}
              {(data.page_button_link_2 && data.page_button_text_2) && <span className='text-warmGray-800'><CheckLink className='bg-white button hover:bg-warmGray-100' link={data.page_button_link_2}>{data.page_button_text_2}</CheckLink></span>}
              </div>
            </div>
          </div>
        </div>
        {data.page_image.fluid &&
          <div className='polygon lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/3'>
            <Img className='h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full' imgStyle={{objectPosition: '80%'}} fluid={data.page_image.fluid} alt={data.page_image.alt} />
          </div>
        }
      </div>
      {data.body !== null && data.body.length > 0 && data.body.map((slice, index) => {
        return (
          <div key={`slice-${index}`}>
            <SliceList slice={slice} />
          </div>
        )
      })}
    </div>
  )
}

const PageTemplate = ({ data }) => {
  const pageData = data.prismicPage.data
  const navigation = data.prismicNavigation
  const social = data.prismicSocial
  return (
    <Layout navigation={navigation} social={social}>
      <Seo title={pageData.meta_title ? (pageData.meta_title):(pageData.title)} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      <RenderBody data={pageData} />
    </Layout>
  )
}

export default withPreview(PageTemplate)
