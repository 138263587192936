import React from 'react'
// import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'


const Partners = ({ slice }) => {
  return (
    <div className={`relative component-${slice.slice_type}`}>
      {slice.primary.title && (
        <div className='prose prose-lg text-center max-w-none' dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
      )}
      <div className='mt-8 flex flex-row'>
        {slice.items.map((partner, index) => {
          return (
            <div key={`partner-${index}`} className='flex-1 max-h-20 px-2'>
              <Img className='max-h-full' imgStyle={{ objectFit: "contain" }} fluid={partner.logo.fluid} alt={partner.logo.alt} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Partners
