import React from 'react'
import PropTypes from 'prop-types'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'

const Card = ({ fields }) => {
  return (
    <li className={`component-card bg-white border rounded shadow-lg overflow-hidden flex flex-col flex-grow relative transform transition text-center duration-150 ${fields.button_link.url && ('hover:scale-105 hover:shadow-xl')} ease-in-out m-1`}>
      {fields.image.fluid && (
        <div className='overflow-hidden transition duration-500 transform'>
          <Img fluid={fields.image.fluid} alt={fields.image.alt} />
        </div>
      )}
      <div className='flex flex-col flex-grow m-4'>
        <div className='space-y-3 pb-6'>
          <div className='prose' dangerouslySetInnerHTML={{__html:fields.title.html}} />
          <div className='prose' dangerouslySetInnerHTML={{__html:fields.text.html}} />
        </div>
        {fields.button_link.url && (
          <CheckLink className='link block mt-auto text-coolGray-600' link={fields.button_link}>{fields.button_text} <span className='inline-block transform transition duration-150 text-red-500 hover:translate-x-full'>→</span></CheckLink>
        )}
      </div>
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired,
}

export default Card
