import React from 'react'
import PropTypes from 'prop-types'
import Card from './card'

const CardDeck = ({ slice }) => {
  var numberOfCards = slice.items.length
  return (
    <div className='space-y-6'>
      {slice.primary.deck_title && (
        <div className='text-center' dangerouslySetInnerHTML={{__html:slice.primary.deck_title.html}} />
      )}
      <ul className={`component-${slice.slice_type} -mx-1 grid grid-cols-1 gap-3 lg:gap-6 ${numberOfCards > '3' ? ('sm:grid-cols-2 xl:grid-cols-4'):('md:grid-cols-3')}`}>
        {slice.items.map((card, index) => {
          return (
            <Card fields={card} key={`card-${index}`} />
          )
        })}
      </ul>
    </div>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default CardDeck
