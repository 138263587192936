import React from 'react'
import PropTypes from 'prop-types'
import CheckLink from './checkLink'


const Text = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} space-y-4`}>
      <div className='prose min-w-full' dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
      {(slice.primary.button_link.url && slice.primary.button_text) && <CheckLink className='text-white bg-gradient-to-r from-red-500 to-red-400 button hover:from-red-600' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink>}
    </div>
  )
}

Text.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Text
